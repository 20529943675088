import { createStore } from 'vuex'

export default createStore({
  state: {
    value: "0",
    charge: "0",
    exchangeRate: "0",
    exitCurrency: "0",
    inputCurrency: "0",
    outputQuantity: "0",
    merchantTransaction: '',
    token: '',
    partner: 0,
    time: 0,
    startTime: 0,
    duration: 0,
    expired:false,
    country: "",
    currency: "",
    errorMessage: "",
    activeOrderTime:[]
  },
  getters: {
    getValue(state){
      return state.value
    },
    getCharge(state){
      return state.charge
    },
    getExchangeRate(state){
      return state.exchangeRate
    },
    getInputCurrency(state){
      return state.inputCurrency
    },
    getExitCurrency(state){
      return state.exitCurrency
    },
    getOutputQuantity(state){
      return state.outputQuantity
    },
    getMerchantTransaction(state){
      return state.merchantTransaction
    },
    getToken(state){
      return state.token
    },
    getCountry(state){
      return state.country
    },
    getCurrency(state){
      return state.currency
    },
    getErrorMessage(state){
      return state.errorMessage
    },
    getPartner(state){
      return state.partner
    },    
    getTime(state){
      return state.time
    },
    getStartTime(state){
      return state.startTime
    },
    getDuration(state){
      return state.duration
    },
    getExpired(state){
      return state.expired
    },
    getActiveOrderTime(state){
      return state.activeOrderTime
    },
  },
  mutations: {
    setExpired(state, value){
      state.expired = value
    },
    setActiveOrderTime(state, value){
      state.activeOrderTime = value
    },
    setValue(state, value){
      state.value = value
    },
    setCharge(state, value){
      state.charge = value
    },    
    setExchangeRate(state, value){
      state.exchangeRate = value
    },
    setInputCurrency(state, value){
      state.inputCurrency = value
    },
    setExitCurrency(state, value){
      state.exitCurrency = value
    },
    setOutputQuantity(state, value){
      state.outputQuantity = value
    },
    setMerchantTransaction(state, merchantTransaction){
      state.merchantTransaction = merchantTransaction
    },
    setToken(state, token){
      state.token = token
    },
    setCountry(state, country){
      state.country = country
    },
    setCurrency(state, currency){
      state.currency = currency
    },
    setErrorMessage(state, errorMessage){
      state.errorMessage = errorMessage
    },   
    setPartner(state, value){
      state.partner = value
    },
    setTime(state, value){
      state.time = value
    },
    setStartTime(state, value){
      state.startTime = value
    },
    setDuration(state, value){
      state.duration = value
    }
  },
  actions: {
    async setStartTime({commit}){
      let startDate = new Date()
      commit('setStartTime', startDate.getTime())
    },
    async setDuration({commit}, duration){
      commit('setDuration', duration)
    }
  },
  modules: {
  }
})
